* {
  word-wrap: break-word;
}

a {
  color: var(--sub-color);
  text-decoration: none;
}

a:hover {
  color: #707070;
}

.flexFont {
  font-size: 42px;
}

.red {
  color: var(--red-color-3);
}

.box {
  background: #53b6b8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 27px;
  opacity: 1;
}

.flex {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  padding: 80px 56px;
  text-align: center;
  word-wrap: break-word;
  font-size: 18px;

  span {
    display: inline-block;
    width: fit-content;
  }

  p {
    word-wrap: break-word;
    font-size: 18px;
    text-align: center;
    line-height: 2em;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .title {
    font-size: 28px;
    letter-spacing: 0.05em;
    margin: 28px auto;
    line-height: 1.8em;
    z-index: 1;
    font-weight: 500;
  }

  .small {
    display: flex;
    flex-wrap: wrap;
  }

  .button {
    background-color: var(--green-color-4);
    font-size: 22px;
    font-weight: 700;
    border-radius: 47px;
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.6));
    padding: 12px 32px;
    margin: auto;
    min-width: 280px;

    a {
      color: var(--main-color);
    }
  }
}

img {
  position: relative;
  height: auto;
  width: 100px;
}

.section_0 {
  overflow: hidden;
  width: 100%;
  margin: auto;
  height: 700px;
  position: relative;
  flex-direction: row;
  img {
    position: absolute;
    height: auto;
    width: 20%;
    margin: 0 auto;
  }

  .title {
    width: 100%;
    margin: 300px 0 100px;
    .flexFont {
      margin: auto auto 16px;
    }
  }
  .small {
    width: 100%;
  }
}

.section_1 {
  img {
    height: auto;
    width: 5%;
  }

  .small {
    display: flex;
    justify-content: stretch;
  }
}

.section_2 {
  .small {
    flex-direction: row;
    justify-content: center;
  }
  .button {
    margin: auto 0;
  }

  img {
    min-width: 200px;
    width: 20%;
  }
}

.section_3 {
  padding: 0;
  position: relative;

  img {
    width: 50%;
    margin: 0 auto;
  }

  h3 {
    font-size: 22px;
    margin: 0;
    font-weight: 600;
  }

  .small > div {
    display: flex;
    flex-direction: column;
    width: 250px;
    max-width: 400px;
    flex-grow: 1;
    margin: 30px 40px;
  }

  .small {
    width: 100%;
    padding: 0 10px 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    font-size: 18px;
    background-color: var(--green-color-3);
    align-items: flex-start;
  }

  .title {
    background-color: var(--green-color-3);
    width: 100%;
    margin: 0;
    padding: 12px 16px 0;
    position: relative;
  }
}

.section_4 {
  img {
    max-width: 1000px;
  }
}

.section_5 {
  padding: 0;
  justify-content: flex-end;

  h3 {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
  }

  .small > div img {
    max-width: 200px;
    width: 100%;
    margin: 22px auto;
  }

  .small > div {
    display: flex;
    flex-direction: column;
    width: 250px;
    max-width: 400px;
    flex-grow: 1;
    margin: 30px 40px;
  }

  .small {
    width: 100%;
    padding: 0 10px 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    font-size: 18px;
    background-color: var(--green-color-3);
    align-items: flex-start;
  }

  .title {
    background-color: var(--green-color-3);
    width: 100%;
    margin: 0;
    padding: 0 16px;
    position: relative;
  }
}

.section_6 {
  .title {
  }
}
.section_7 {
  .small {
    line-height: 2.2em;
  }
}

@media screen and (max-width: 480px) {
  .flexFont {
    font-size: 32px;
  }

  .flex {
    padding: 64px 16px;
    line-height: 2em;
    font-size: 16px;
    div {
      font-size: 16px;
      line-height: 1.8em;
    }

    .flexFont {
      font-size: 32px;
    }

    p {
      font-size: 16px;
      line-height: 1.8em;
    }
    .small {
      letter-spacing: 0.1rem;
    }
    .title {
      line-height: 1.5em;
      font-size: 24px;
    }
    h3 {
      font-size: 18px;
    }
  }

  .section_0 {
    img {
      width: 150px;
    }

    div:first-child {
      img:nth-child(4n + 1) {
        display: none;
      }
    }
  }
  .section_3,
  .section_5 {
    padding: 0;
  }
  .section_6,
  .section_4 {
    .title {
      font-size: 18px;
    }
  }

  .icons {
    .icon {
      margin: 32px 0;
    }
  }
}

@keyframes return {
  50% {
    // left: 200px;
    /* 単一の <length-percentage> 値 */
    transform: translate(-10px);
  }

  100% {
    // left: 0px;
    /* 単一の <length-percentage> 値 */
    transform: translate(10px);
  }
}

.move {
  transition: all 0.5s 0s ease;
  /* transitionの追加記述 */
  animation-name: return;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}
