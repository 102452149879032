.flex {
  max-width: 1000px;
  background-color: var(--main-color-2);
  margin: 22px auto;
  padding: 16px 12px 80px;

  h1 {
    border-bottom: solid var(--yellow-color) 3px;
    padding: 8px 32px;
    margin: 40px 0;
  }

  h2 {
    padding: 8px 32px;
    margin-top: 40px;
    background-color: var(--sub-color);
    color: var(--main-color);
  }

  p {
    padding: 8px 40px;
  }

  ol {
    padding: 8px 40px;
    li {
      padding: 8px 8px 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .detail {
    padding: 0 0 80px;
    p {
      padding: 8px 32px;
    }
    ol {
      padding: 4px 4px 4px 40px;
      li {
        padding: 8px 8px 0;
      }
    }
  }
}
