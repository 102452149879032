main {
  /* background-color: rgb(251, 255, 228); */
}

#header {
}

#footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  color: var(--bg-color);
  z-index: 9998;
}

#footer a {
  color: var(--bg-color);
}

#alert {
  position: fixed;
  top: 64px;
  z-index: 1;
  opacity: 0.9;
  width: 350px;
  left: 50%;
  transform: translate(-50%);
}

pre {
  width: 100%;
  white-space: pre-wrap;
}
