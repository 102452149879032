.poster {
  position: relative;
  min-width: 100px;
  width: 100%;
  height: auto;
  max-width: 100%;
}

.poster > * {
  overflow-wrap: break-word;
  width: 100%;
  height: auto;
  text-align: center;
  white-space: pre-wrap;
}
.poster .url {
  border: 1px solid;
  padding: 8px 18px;
  border-radius: 10px;
}
.poster .url:hover {
  background: rgba(255, 255, 255, 0.4);
  text-decoration: underline;
}

#board {
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-width: 1300px;
  min-height: 1000px;
  margin: 0;
  padding: 0;
  background: transparent url(/public/board-background.jpg) repeat;
  background-size: cover;
  background-position: top;
  overflow: hidden;
  font-size: 15px;
}

#board .posterCard {
  width: 150px;
  height: auto;
  position: absolute;
  top: 40px;
  left: 30px;
  -webkit-transform: rotate(4deg);
  -moz-transform: rotate(4deg);
  -o-transform: rotate(4deg);
  box-shadow: none;
  box-sizing: border-box;
  overflow: visible;
}

#board .posterCard::before {
  content: "";
  position: absolute;
  z-index: -1; /*影を下の層に持っていく*/
  bottom: 10px;
  display: block;
  width: 90%;
  height: 90%;
  box-shadow: 0 12px 5px rgba(0, 0, 0, 0.298);
}

#board .posterCard::before {
  left: 5px;
  transform: rotate(-3deg);
}

#board .posterCard button:hover::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  border-width: 0 30px 30px 0;
  border-style: solid;
  width: 0;
  transform: rotate(270deg);
  border-color: #e8ddbd #e8ddbd white white;
  z-index: 1;
}

#board .posterCard .pin {
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  filter: drop-shadow(-1px 3px 1px rgba(0, 0, 0, 0.25));
}

#board .posterCard button p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    font-size: 1rem;
    white-space: pre-wrap;
  }
}

#cover {
  position: absolute;
  width: 1200px;
  height: 700px;
  margin: auto;
  padding: 0;
  overflow: hidden;
  z-index: 2;
  background-color: rgba(249, 249, 249, 0.468);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  cursor: pointer;
}

#cover #moveImage {
  width: 150px;
  height: 200px;
  background-color: gray;
  position: absolute;
  z-index: 3;
}

#cover #moveImage > img {
  height: 100%;
}

#saveEventMap {
  pointer-events: none;
}

#saveEventMap > div {
}

.status {
  background-color: var(--g-color);
  width: fit-content;
  padding: 4px 12px 6px;
  position: relative;
  display: inline-block;
  margin: 5px 0;
  border-radius: 15px;
  color: var(--g-font-color);
}

.status:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top: 5px solid var(--g-color);
}

.status.available {
  color: white;
  background-color: var(--bl-color);
  filter: drop-shadow(-1px 3px 1px rgba(0, 0, 0, 0.077));
}

.status.available:before {
  border-top: 5px solid var(--bl-color);
}

.sharelink > a {
  border: 2px solid;
  background: rgba(255, 255, 255, 1);
}

.sharelink > a:hover {
  background: rgba(255, 255, 255, 0.4);
}

#addPoster {
  position: fixed;
  right: 48px;
  bottom: 48px;
}

.themeChoose {
  background: rgb(236, 236, 236);
}

#loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.621);
  cursor: wait;
}

@media screen and (max-width: 480px) {
  #board {
    position: relative;
    left: 0;
    min-width: unset;
    min-height: unset;
    margin: 0;
    padding: 80px 0;
    background: transparent url(/public/board-background.jpg) repeat;
    background-size: cover;
    background-position: top;
    overflow: scroll;
    font-size: 15px;
  }

  #board .posterCard {
    margin: 16px;
    width: calc(100% - 32px);
    height: auto;
    position: relative;
    box-shadow: none;
    box-sizing: border-box;
    overflow: visible;
    top: 0 !important;
    left: 0 !important;
    transform: unset !important;
  }
}
