:root {
  --main-color: #fcfafa;
  --main-color-2: #fffefe;
  --sub-color: #000000;
  --sub-color-2: #dfdfdf;
  // --yellow-color:#F3B61F;
  --yellow-color-2: #fef6e3;
  --green-color: #1d572e;
  --green-color-2: #daf3e2;
  --green-color-3: #dcef94;
  --green-color-4: #53b6b8;
  --red-color: #cd5e79;
  --red-color-2: #d28b9d;
  --red-color-3: #db617f;
  --brown-color: #707070;

  --r-color: #d0607a;
  --g-color: #80a497;
  --b-color: #b7cde2;
  --y-color: #f9d682;
  --bg-color: #665257;

  --gl-color: #e0ed9f;
  --bl-color: #427696;
  --gl-color: #41b5b7;

  --g-color: rgba(0, 0, 0, 0.12);
  --g-font-color: rgba(0, 0, 0, 0.26);
  --w-color: white;
  --b-color: black;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  box-sizing: border-box;
}

a {
  color: var(--sub-color);
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  display: block;
}

button {
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}
